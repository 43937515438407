var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "block" },
      [
        _c(
          "div",
          { staticClass: "header" },
          [
            _c("h3", [_vm._v("Subscription history")]),
            _c("span", { staticClass: "total_tickets" }, [
              _vm._v(
                _vm._s(_vm.org.total_tickets) +
                  " total " +
                  _vm._s(_vm._f("pluralize")("ticket", _vm.org.total_tickets)) +
                  " claimed"
              ),
            ]),
            _c(
              "el-button",
              {
                attrs: { type: "primary", disabled: _vm.isNewSubscription },
                on: {
                  click: function ($event) {
                    return _vm.$emit("add")
                  },
                },
              },
              [_vm._v("Add subscription")]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            ref: "table",
            attrs: {
              data: _vm.subscriptions,
              "highlight-current-row": "",
              "span-method": _vm.tableSpan,
            },
            on: { "current-change": _vm.selectRow },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "selection", width: "50" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("i", {
                        staticClass: "row-icon el-icon-caret-right",
                        class: {
                          visible:
                            _vm.selected && scope.row.id === _vm.selected.id,
                        },
                      }),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "Active subscription",
                            placement: "top",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "row-icon el-icon-date",
                            class: { visible: scope.row.is_active },
                          }),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Subscription period", prop: "period" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("dateformat")(scope.row.start_date)) +
                          " - " +
                          _vm._s(_vm._f("dateformat")(scope.row.end_date)) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Price", prop: "price" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " + _vm._s(_vm._f("currency")(scope.row.price)) + " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Ticket duration", prop: "duration" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " + _vm._s(scope.row.ticket_duration) + " months "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "Tickets", prop: "tickets" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.deleted_at != null
                        ? _c(
                            "el-tag",
                            {
                              staticClass: "ticket-info",
                              attrs: { type: "info" },
                            },
                            [
                              _vm._v(
                                " TERMINATED - " +
                                  _vm._s(scope.row.all_tickets_claimed) +
                                  " / " +
                                  _vm._s(
                                    scope.row.ticket_count > 0
                                      ? scope.row.ticket_count
                                      : "unlimited"
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : scope.row.ticket_count > 0
                        ? _c(
                            "el-tag",
                            {
                              staticClass: "ticket-info",
                              attrs: {
                                type: _vm.subscriptionHealth(scope.row),
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.tickets_claimed) +
                                  " / " +
                                  _vm._s(scope.row.ticket_count) +
                                  " "
                              ),
                            ]
                          )
                        : _c("el-tag", { staticClass: "ticket-info" }, [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.tickets_claimed) +
                                " / unlimited "
                            ),
                          ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "70" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return scope.row.deleted_at == null
                        ? [
                            _c("el-button", {
                              attrs: {
                                type: "danger",
                                size: "small",
                                icon: "el-icon-delete",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("delete", scope.row)
                                },
                              },
                            }),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }