<template>
    <div>
        <div class="block">

            <div class="header">
              <h3>Subscription history</h3>
              <span class="total_tickets">{{org.total_tickets}} total {{ 'ticket' | pluralize(org.total_tickets) }} claimed</span>
              <el-button type="primary" :disabled="isNewSubscription" @click="$emit('add')">Add subscription</el-button>
            </div>

            <el-table
                ref="table"
                :data="subscriptions"
                highlight-current-row
                :span-method="tableSpan"
                @current-change="selectRow"
                >
              <el-table-column prop="selection" width="50">
                <template slot-scope="scope">
                  <i :class="{visible: selected && scope.row.id === selected.id}" class="row-icon el-icon-caret-right" />
                  <el-tooltip content="Active subscription" placement="top">
                    <i :class="{visible: scope.row.is_active}" class="row-icon el-icon-date" />
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="Subscription period" prop="period">
                <template slot-scope="scope">
                  {{ scope.row.start_date | dateformat }}
                  - {{ scope.row.end_date | dateformat }}
                </template>
              </el-table-column>
              <el-table-column label="Price" prop="price">
                <template slot-scope="scope">
                  {{ scope.row.price | currency }}
                </template>
              </el-table-column>
              <el-table-column label="Ticket duration" prop="duration">
                <template slot-scope="scope">
                  {{ scope.row.ticket_duration }} months
                </template>
              </el-table-column>
              <el-table-column label="Tickets" prop="tickets">
                <template slot-scope="scope">
                  <el-tag class="ticket-info" v-if="scope.row.deleted_at != null" type="info">
                    TERMINATED - {{ scope.row.all_tickets_claimed }} /
                    {{ scope.row.ticket_count > 0 ? scope.row.ticket_count : "unlimited" }}
                  </el-tag>
                  <el-tag class="ticket-info" v-else-if="scope.row.ticket_count > 0" :type="subscriptionHealth(scope.row)">
                    {{ scope.row.tickets_claimed }}
                    / {{ scope.row.ticket_count }}
                  </el-tag>
                  <el-tag v-else class="ticket-info">
                    {{ scope.row.tickets_claimed }} / unlimited
                  </el-tag>
                </template>
              </el-table-column>

              <el-table-column width="70">
                <template slot-scope="scope" v-if="scope.row.deleted_at == null">
                  <el-button type="danger" size="small" icon="el-icon-delete" @click="$emit('delete', scope.row)" />
                </template>
              </el-table-column>

            </el-table>


        </div>

    </div>
</template>

<script>
    import {mixin as clickaway} from 'vue-clickaway';
    import moment from "moment";

    export default {
        name: 'subscription-list',
        props: {
            org: {
                type: Object,
                default: null,
            },
            selected: {
                type: Object,
                default: null,
            }
        },
        mixins: [clickaway],
        data() {
          return {
          }
        },
        computed: {
          subscriptions() {
            if(this.org && this.org.subscriptions) {
              return [...this.org.subscriptions].filter(sub => {
                // include current subscriptions and deleted subs that had tickets issued
                return sub.deleted_at === null || sub.all_tickets_claimed > 0
              }).sort((a, b) => {
                return moment(b.start_date).unix() - moment(a.start_date).unix();
              });
            }
            return [];
          },
          isNewSubscription() {
            return this.selected && !this.selected.id;
          }
        },
        mounted() {
          if(this.selected) {
            this.setSelectedRow();
          }
        },
        watch: {
          selected(newval, oldval) {
            if (newval != oldval) {
              if (newval != null) {
                this.setSelectedRow();
              } else {
                this.$refs.table.setCurrentRow();
              }
            }
          }
        },
        methods: {
          subscriptionHealth(sub) {
            const claimRatio = sub.tickets_available / sub.ticket_count;
            if(claimRatio < 0.1) return "danger";
            if(claimRatio < 0.5) return "warning";
            return "success";
          },
          setSelectedRow() {
            this.org.subscriptions.forEach(sub => {
              if(sub.id === this.selected.id) {
                this.$refs.table.setCurrentRow(sub);
              }
            });
          },
          selectRow(newrow) {
            this.$emit('select',newrow);
          },
          tableSpan({row, column, rowIndex, columnIndex}) {
            if (row.deleted_at != null) {
              if (columnIndex === 4) {
                return [1, 2];
              }
              if (columnIndex > 4) {
                return [0,0];''
              }
            }
          }
        }
    };
</script>

<style lang="scss" scoped>
  .header {
    display: flex;
    align-items: center;
    margin: 10px 0;
    justify-content: space-between;
    h3 {
      flex: 1;
    }
  }
  .ticket-info {
    width: 100%;
    text-align: center;
    font-weight: bold;
  }
  .row-icon {
    visibility: hidden;
    &.visible {
      visibility: visible;
    }
  }
  .total_tickets {
    margin-right: 20px;
    font-size: 14px;
    font-weight: 500;
  }
  /deep/ .current-row {
    background-color: #f8fcff;
  }
</style>
