var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      !_vm.loading
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "org-panel", attrs: { span: 8 } },
                [
                  _c("org-panel", {
                    staticClass: "content",
                    attrs: {
                      org: _vm.org,
                      selectedSubscription: _vm.selectedSubscription,
                      loading: _vm.subsLoading,
                    },
                    on: {
                      add: _vm.addSubscription,
                      save: _vm.saveSubscription,
                      cancel: _vm.selectSubscription,
                      delete: _vm.handleDelete,
                      tickets: function ($event) {
                        _vm.showAddTicketsDialog = true
                      },
                      unlimited: function ($event) {
                        _vm.showUnlimitedDialog = true
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "subscription-list", attrs: { span: 16 } },
                [
                  _c("subscription-list", {
                    attrs: { org: _vm.org, selected: _vm.selectedSubscription },
                    on: {
                      select: _vm.selectSubscription,
                      add: _vm.addSubscription,
                      delete: _vm.handleDelete,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "confirmDialog",
          attrs: { visible: _vm.showAddTicketsDialog, title: "Add tickets" },
          on: {
            "update:visible": function ($event) {
              _vm.showAddTicketsDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("p", [
              _vm._v(
                " How many tickets do you want to add to this subscription? "
              ),
            ]),
            _c(
              "p",
              [
                _c("el-input-number", {
                  model: {
                    value: _vm.ticketsToAdd,
                    callback: function ($$v) {
                      _vm.ticketsToAdd = $$v
                    },
                    expression: "ticketsToAdd",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showAddTicketsDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addTickets } },
                [_vm._v("Add")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "confirmDialog",
          attrs: {
            visible: _vm.showUnlimitedDialog,
            title: "Convert to unlimited",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showUnlimitedDialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("p", [
              _vm._v(
                " Convert this subscription to unlimited tickets? This operation is not reversible. "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showUnlimitedDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.makeUnlimited },
                },
                [_vm._v("Convert")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "confirmDialog",
          attrs: {
            visible: _vm.showDeleteDialog,
            title: "Delete subscription",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDeleteDialog = $event
            },
          },
        },
        [
          _vm.subscriptionToDelete
            ? _c("div", { staticClass: "content" }, [
                _c("p", [
                  _vm._v(
                    " Subscription period: " +
                      _vm._s(
                        _vm._f("dateformat")(
                          _vm.subscriptionToDelete.start_date
                        )
                      ) +
                      " - " +
                      _vm._s(
                        _vm._f("dateformat")(_vm.subscriptionToDelete.end_date)
                      ) +
                      " "
                  ),
                ]),
                _vm.isTerminatingSubscription
                  ? _c("p", [
                      _vm._v(" This subscription has had tickets claimed."),
                      _c("br"),
                      _vm._v(
                        " The subscription, and all issued tickets, will be terminated."
                      ),
                      _c("br"),
                      _vm._v(
                        " Are you sure you want to continue? This action cannot be undone. "
                      ),
                    ])
                  : _c("p", [
                      _vm._v(
                        " This subscription has not had any tickets claimed, and will be permanently deleted."
                      ),
                      _c("br"),
                      _vm._v(
                        " Are you sure you want to continue? This action cannot be undone. "
                      ),
                    ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDeleteDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: { click: _vm.deleteSubscription },
                },
                [
                  _vm.isTerminatingSubscription
                    ? _c("span", [_vm._v(" Terminate ")])
                    : _c("span", [_vm._v(" Delete ")]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }