<template>
    <div class="app-container" v-loading="loading">

        <el-row :gutter="20" v-if="!loading">

            <el-col :span="8" class="org-panel">
                <org-panel class="content"
                    :org="org"
                    :selectedSubscription="selectedSubscription"
                    :loading="subsLoading"
                    @add="addSubscription"
                    @save="saveSubscription"
                    @cancel="selectSubscription"
                    @delete="handleDelete"
                    @tickets="showAddTicketsDialog = true"
                    @unlimited="showUnlimitedDialog = true"
                />
            </el-col>
            <el-col :span="16" class="subscription-list">
                <subscription-list
                    :org="org"
                    :selected="selectedSubscription"
                    @select="selectSubscription"
                    @add="addSubscription"
                    @delete="handleDelete"
                />
            </el-col>
        </el-row>

        <el-dialog class="confirmDialog" :visible.sync="showAddTicketsDialog" title="Add tickets">
          <div class="content">
            <p>
              How many tickets do you want to add to this subscription?
            </p>
            <p>
              <el-input-number v-model="ticketsToAdd" />
            </p>
          </div>
          <div class="footer">
            <el-button @click="showAddTicketsDialog = false">Cancel</el-button>
            <el-button type="primary" @click="addTickets">Add</el-button>
          </div>
        </el-dialog>

        <el-dialog class="confirmDialog" :visible.sync="showUnlimitedDialog" title="Convert to unlimited">
          <div class="content">
            <p>
              Convert this subscription to unlimited tickets? This operation is not reversible.
            </p>
          </div>
          <div class="footer">
            <el-button @click="showUnlimitedDialog = false">Cancel</el-button>
            <el-button type="primary" @click="makeUnlimited">Convert</el-button>
          </div>
        </el-dialog>

        <el-dialog class="confirmDialog" :visible.sync="showDeleteDialog" title="Delete subscription">
          <div class="content" v-if="subscriptionToDelete">
            <p>
              Subscription period: {{ subscriptionToDelete.start_date | dateformat }} - {{ subscriptionToDelete.end_date | dateformat }}
            </p>
            <p v-if="isTerminatingSubscription">
              This subscription has had tickets claimed.<br/>
              The subscription, and all issued tickets, will be terminated.<br/>
              Are you sure you want to continue? This action cannot be undone.
            </p>
            <p v-else>
              This subscription has not had any tickets claimed, and will be permanently deleted.<br/>
              Are you sure you want to continue? This action cannot be undone.
            </p>
          </div>
          <div class="footer">
            <el-button @click="showDeleteDialog = false">Cancel</el-button>
            <el-button type="danger" @click="deleteSubscription">
              <span v-if="isTerminatingSubscription">
                Terminate
              </span>
              <span v-else>
                Delete
              </span>
            </el-button>
          </div>
        </el-dialog>

    </div>
</template>

<script>
    import OrgPanel from '@/views/orgs/OrgPanel';
    import SubscriptionList from '@/views/orgs/SubscriptionList';
    import moment from 'moment';
    import axios from 'axios';
    import _ from 'lodash';

    export default {
        name: 'org-profile',
        data() {
            return {
                org: {
                    id: this.$route.params.org_id,
                    name: null,
                    waiting_count: 0,
                    subscriptions: [],
                },
                selectedSubscription: null,
                loading: false,
                subsLoading: false,
                showDeleteDialog: false,
                subscriptionToDelete: null,
                showAddTicketsDialog: false,
                ticketsToAdd: 10,
                showUnlimitedDialog: false,
            };
        },
        components: {
            OrgPanel,
            SubscriptionList,
        },
        computed: {
          isTerminatingSubscription() {
             return this.subscriptionToDelete && this.subscriptionToDelete.tickets_claimed > 0;
          }
        },
        async mounted() {
          await this.getOrg();
        },
        methods: {
          async getOrg() {
            this.loading=true;

            const token = await this.$auth.getTokenSilently();

            var query = `${process.env.VUE_APP_BASE_URI}/organisations?includes[]=subscriptions`;
            axios.get(query,{
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
            .then(response => {
                const data = response.data.data;
                console.log(data);
                if(data) {
                  const org = data.organisations.find(org => org.id == this.$route.params.org_id);
                  if(!org) {
                    this.$router.push('/orgs/');
                  } else {
                    this.org = org;
                    this.selectCurrentSubscription();
                  }
                }
            })
            .catch((error) => {
                console.log("Org API error",error,error.response);
            })
            .finally(()=> {
              this.loading = false;
            });
          },

          async addSubscription() {
            if(this.org) {
              let start_date = moment().startOf('day');
              let end_date = moment(start_date).add(12, 'months').subtract(1, 'day');

              const subs = [...this.org.subscriptions].sort((a,b) => {
                return moment(a.start_date).unix() - moment(b.start_date).unix();
              });

              // find first valid start date from today
              for (var i = 0; i < subs.length; i++) {
                const sub = subs[i];
                if(sub.deleted_at === null) {
                  if (start_date.isSameOrAfter(moment(sub.start_date)) && start_date.isSameOrBefore(moment(sub.end_date))) {
                    start_date = moment(sub.end_date).add(1, 'day');
                    end_date = moment(sub.end_date).add(12, 'months').subtract(1, 'day');
                  } else if (start_date.isSameOrBefore(moment(sub.start_date)) && end_date.isSameOrAfter(moment(sub.start_date))) {
                    end_date = moment(sub.start_date).subtract(1, 'day');
                    break;
                  }
                }
              }

              // initialise new subscription with default values
              const newSubscription = {
                start_date: start_date.toDate(),
                end_date: end_date.toDate(),
                price: 0,
                ticket_duration: 12,
                ticket_count: 10,
                isUnlimited: false,
              }
              this.selectedSubscription = newSubscription;
            }
          },

          async saveSubscription(sub) {
            this.loading=true;

            const token = await this.$auth.getTokenSilently();

            var config = {
              url:`${process.env.VUE_APP_BASE_URI}/organisations/${this.org.id}/subscriptions`,
              headers: {
                'Authorization': `Bearer ${token}`
              },
              data: sub,
              method:'post',
            }

            axios(config)
            .then(() => {
                this.getOrg();
                this.loading = false;
            })
            .catch((error) => {
                this.loading = false;
                console.log("Sub add API error",error,error.response);
                this.$message.error({
                  duration: 5000,
                  message: error.response.data.message,
                })
            });
          },

          handleDelete(sub) {
            this.subscriptionToDelete = sub;
            this.showDeleteDialog = true;
          },

          async deleteSubscription() {
            this.showDeleteDialog = false;
            const sub = this.subscriptionToDelete;
            if(sub) {
              this.loading=true;

              const token = await this.$auth.getTokenSilently();

              var query = `${process.env.VUE_APP_BASE_URI}/subscriptions/${sub.id}`;
              axios.delete(query,{
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              })
              .then(() => {
                this.getOrg();
              })
              .catch((error) => {
                  console.log("Sub delete API error",error,error.response);
              })
              .finally(() => {
                this.subscriptionToDelete = null;
                this.loading = false;
              });
            }
          },

          async addTickets() {
            this.showAddTicketsDialog = false;
            if(this.selectedSubscription && this.ticketsToAdd > 0) {
              this.loading=true;

              const token = await this.$auth.getTokenSilently();

              var config = {
                url:`${process.env.VUE_APP_BASE_URI}/subscriptions/${this.selectedSubscription.id}`,
                headers: {
                  'Authorization': `Bearer ${token}`
                },
                data: {
                  ticket_count: this.selectedSubscription.ticket_count + this.ticketsToAdd,
                },
                method:'put',
              }

              axios(config)
                  .then((response) => {
                    const data = response.data.data;
                    this.selectedSubscription.ticket_count = data.ticket_count;
                    this.selectedSubscription.tickets_available = data.tickets_available;
                    this.loading = false;
                  })
                  .catch((error) => {
                    this.loading = false;
                    console.log("Sub addTickets API error",error,error.response);
                  });
            }
          },

          async makeUnlimited() {
            this.showUnlimitedDialog = false;
            if(this.selectedSubscription) {
              this.loading=true;

              const token = await this.$auth.getTokenSilently();

              var config = {
                url:`${process.env.VUE_APP_BASE_URI}/subscriptions/${this.selectedSubscription.id}`,
                headers: {
                  'Authorization': `Bearer ${token}`
                },
                data: {
                  ticket_count: 0,
                },
                method:'put',
              }

              axios(config)
                  .then((response) => {
                    const data = response.data.data;
                    this.selectedSubscription.ticket_count = data.ticket_count;
                    this.selectedSubscription.tickets_available = data.tickets_available;
                    this.loading = false;
                  })
                  .catch((error) => {
                    this.loading = false;
                    console.log("Sub makeUnlimited API error",error,error.response);
                  });
            }
          },

          selectCurrentSubscription() {
            this.selectedSubscription = this.org.subscriptions.find(sub => sub.is_active);
          },

          selectSubscription(sub) {
            if (sub == null) {
                this.selectCurrentSubscription();
            } else {
                this.selectedSubscription = sub;
            }
          },

        },
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

    .app-container {
        padding: 0;
        min-height: calc(100vh - 50px);
        overflow: hidden;

        .el-row {
            margin: 0 !important;
        }
    }

    .org-header {
      margin-left: 40px;
    }

    .org-panel {
        padding: 10px 40px 50px 40px !important;
        box-sizing: border-box;
        background-color: white;
        height: calc(100vh - 50px);
        overflow: scroll;
    }

    .participant-list {
        padding: 10px 40px !important;
        box-sizing: border-box;
        background-color: #F0F2F5;
        height: calc(100vh - 50px);
        overflow: scroll;
    }

    .confirmDialog {
      /deep/ .el-dialog {
        width: 640px;
        max-width: calc(100% - 40px);
        .content {
          text-align: center;
        }
      }
      .footer {
        margin-top: 20px;
        display: flex;
        > * {
          flex-grow: 1;
        }
      }
    }
</style>
