<template>
  <div>
        <div class="header">
          <h3 class="org-header">{{org.name}}</h3>
          <el-button type="primary" :disabled="isNewSubscription" @click="$emit('add')">Add subscription</el-button>
        </div>

        <el-tag class="ticket-info" v-if="org.waiting_count > 0" type="danger">
          {{ org.waiting_count }} waiting for tickets
        </el-tag>

        <el-card v-if="isNewSubscription" class="subscription-box">
          <div slot="header" class="header">
            <span class="title">Add subscription</span>
          </div>

          <el-form class="subscription-details" :model="newSubscription" :rules="rules" ref="addSubscription">
            <el-form-item label="Subscription period">
              <el-date-picker v-model="newSubscriptionPeriod"
                  type="daterange"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  format="MMM d, yyyy"
                  :picker-options="pickerOptions"
                  @change="datePicked"
                  @blur="datePicked"
                  class="subscription-date-range"
              />
            </el-form-item>
            <el-form-item label="Price" prop="price">
              <money v-model="newSubscription.price" v-bind="money" class="el-input__inner money" />
            </el-form-item>
            <el-form-item label="Ticket duration" prop="ticket_duration">
              <el-input-number v-model="newSubscription.ticket_duration" /> months
            </el-form-item>
            <el-form-item label="Tickets" prop="ticket_count">
              <div>
                <el-input-number :disabled="newSubscription.isUnlimited" v-model="newSubscription.ticket_count" />
                <el-switch v-model="newSubscription.isUnlimited" active-text="unlimited" />
              </div>
            </el-form-item>
            <el-divider />
            <div class="actions">
              <el-button type="primary" @click="saveSubscription">Save</el-button>
            </div>
            <div class="actions">
              <el-button @click="cancelAdd">Cancel</el-button>
            </div>
          </el-form>
        </el-card>

        <el-card v-else-if="selectedSubscription" class="subscription-box display">
          <div slot="header" class="header">
            <span class="title">Selected subscription</span>
            <span v-if="selectedSubscription.is_active" class="active"><i class="el-icon-date" /> Active</span>
          </div>

          <el-form class="subscription-details">
            <el-form-item label="Subscription period" prop="period">
              <div>{{ selectedSubscription.start_date | dateformat }}
                - {{ selectedSubscription.end_date | dateformat }}</div>
            </el-form-item>
            <el-form-item label="Price">
              <div>{{ selectedSubscription.price | currency }}</div>
            </el-form-item>
            <el-form-item label="Ticket duration">
              <div>{{ selectedSubscription.ticket_duration }} months</div>
            </el-form-item>
            <el-form-item label="Tickets" v-if="selectedSubscription.deleted_at == null">
              <el-tag class="ticket-info" v-if="selectedSubscription.ticket_count > 0" :type="subscriptionHealth(selectedSubscription)">
                {{ selectedSubscription.tickets_claimed }}
                / {{ selectedSubscription.ticket_count }}
              </el-tag>
              <el-tag class="ticket-info" v-else>
                {{ selectedSubscription.tickets_claimed }} / Unlimited
              </el-tag>
            </el-form-item>
            <el-form-item label="Tickets" v-else>
              <el-tag class="ticket-info" v-if="selectedSubscription.ticket_count > 0" :type="subscriptionHealth(selectedSubscription)">
                {{ selectedSubscription.all_tickets_claimed }}
                / {{ selectedSubscription.ticket_count }}
              </el-tag>
              <el-tag class="ticket-info" v-else>
                {{ selectedSubscription.all_tickets_claimed }} / Unlimited
              </el-tag>
            </el-form-item>
            <template v-if="selectedSubscription.deleted_at == null">
              <div v-if="!isPastSubscription && selectedSubscription.ticket_count > 0" class="actions">
                <el-button type="primary" plain @click="$emit('tickets')">Add tickets</el-button>
                <el-button type="primary" plain @click="$emit('unlimited')">Convert to unlimited</el-button>
              </div>
              <el-divider />
              <div class="actions">
                <el-button type="danger" @click="$emit('delete', selectedSubscription)">
                  <span v-if="selectedSubscription.tickets_claimed > 0">
                    Terminate subscription
                  </span>
                  <span v-else>
                    Delete subscription
                  </span>
                </el-button>
              </div>
            </template>
            <div v-else>
              <el-divider />
              <el-tag class="ticket-info" type="info">TERMINATED</el-tag>
            </div>
          </el-form>
        </el-card>

        <el-card v-else class="subscription-box display no-sub">
          <p>No subscription selected.</p>
        </el-card>

  </div>
</template>

<script>
    import moment from 'moment';
    import { Money } from 'v-money'

    export default {
        name: 'org-panel',
        components: {
          Money,
        },
        props: {
            org: {
                required: true,
                type: Object,
            },
            selectedSubscription: {
              required: false,
              type: Object,
            },
            loading: {
              required: false,
              type: Boolean,
              default: false,
            }
        },

        watch: {
          selectedSubscription(newval, oldval) {
            if(newval && newval != oldval && !newval.id) {
              this.newSubscription = newval;
              this.newSubscriptionPeriod = [newval.start_date, newval.end_date];
            }
          }
        },

        data() {
            var vm = this;
            return {
              showSubscriptionDialog: false,
              showDeleteDialog: false,
              newSubscription: {
                price: 0,
                ticket_duration: 12,
                isUnlimited: false,
              },
              newSubscriptionPeriod: null,
              dateWindow: null,
              rules : {
                // period: [{required: true, validator: this.validateSubscriptionPeriod, trigger: 'blur'}],
                ticket_duration: [{required: true, message: 'Please enter a duration in months', trigger: 'blur'}],
                // ticket_count: [{required: true, validator: this.validateTicketCount, trigger: 'blur'}],
              },
              pickerOptions: {
                shortcuts: [{
                  text: '3 months',
                  onClick(picker) {
                    const start = new Date();
                    const end = moment(start)
                        .add(3, 'months')
                        .subtract(1, 'day').toDate();
                    picker.$emit('pick', [start, end]);
                  }
                }, {
                  text: '6 months',
                  onClick(picker) {
                    const start = new Date();
                    const end = moment(start)
                        .add(6, 'months')
                        .subtract(1, 'day').toDate();
                    picker.$emit('pick', [start, end]);
                  }
                }, {
                  text: '12 months',
                  onClick(picker) {
                    const start = new Date();
                    const end = moment(start)
                        .add(1, 'year')
                        .subtract(1, 'day').toDate();
                    picker.$emit('pick', [start, end]);
                  }
                }],
                disabledDate(d) {
                  if(vm.dateWindow) {
                    // disable all dates preceding end of first subscription before dateWindow,
                    // and all dates following start of first subscription after dateWindow
                    let lastInvalidBefore = null;
                    let firstInvalidAfter = null;
                    for (let i = 0; i < vm.subscriptions.length; i++) {
                      if(vm.subscriptions.deleted_at == null) {
                        const sub = vm.subscriptions[i];
                        const date = moment(vm.dateWindow);
                        const start = moment.utc(sub.start_date).local(true);
                        const end = moment.utc(sub.end_date).local(true);
                        console.log("Sub:", i, sub.start_date, sub.end_date, start, end, sub);
                        if (date.isAfter(end) && (lastInvalidBefore === null || end.isAfter(lastInvalidBefore))) {
                          lastInvalidBefore = end;
                        }
                        if (date.isBefore(start) && (firstInvalidAfter === null || start.isBefore(firstInvalidAfter))) {
                          firstInvalidAfter = start;
                        }
                      }
                    }
                    if (lastInvalidBefore !== null && moment(d).isSameOrBefore(lastInvalidBefore)) return true;
                    if (firstInvalidAfter !== null && moment(d).isSameOrAfter(firstInvalidAfter)) return true;

                  } else {
                    // disable dates included in existing subscriptions
                    for (let i = 0; i < vm.subscriptions.length; i++) {
                      const sub = vm.subscriptions[i];
                      const start = moment.utc(sub.start_date).local(true);
                      const end = moment.utc(sub.end_date).local(true);
                      const date = moment(d);
                      if (date.isSameOrAfter(start) && date.isSameOrBefore(end)) {
                        return true;
                      }
                    }
                  }
                  return false;
                },
                onPick(range) {
                  // set date window to first date picked
                  vm.dateWindow = range.minDate;
                }
              },
              money: {
                  decimal: '.',
                  thousands: ',',
                  prefix: '$',
                  precision: 2,
                  masked: false
              },
            };
        },
        computed: {
          subscriptions() {
              return this.org.subscriptions.filter(sub => sub.deleted_at == null);
          },
          isPastSubscription() {
            const now = moment();
            return (
                moment(this.selectedSubscription.end_date).isBefore(now)
            );
          },
          isNewSubscription() {
            return this.selectedSubscription && !this.selectedSubscription.id;
          }
        },
        methods: {
          selectSubscription(sub) {
            //this.selectedSubscription = sub;
            this.$emit('select',sub);
          },

          saveSubscription() {
            if (this.newSubscriptionPeriod && this.newSubscriptionPeriod[0] && this.newSubscriptionPeriod[1]) {
              this.newSubscription.start_date = moment(this.newSubscriptionPeriod[0]).format()
              this.newSubscription.end_date = moment(this.newSubscriptionPeriod[1]).format()
              if (this.newSubscription.isUnlimited) {
                this.newSubscription.ticket_count = 0;
              }
            }
            this.$emit('save', this.newSubscription);
          },
          cancelAdd() {
            this.$emit('cancel', null);
          },

          subscriptionHealth(sub) {
            if(!sub) return 'info';
            const claimRatio = sub.tickets_available / sub.ticket_count;
            if(claimRatio < 0.1) return "danger";
            if(claimRatio < 0.5) return "warning";
            return "success";
          },

          validateSubscriptionPeriod(rule, value, callback) {
            if (!this.newSubscriptionPeriod || !this.newSubscriptionPeriod[0] || !this.newSubscriptionPeriod[1]) {
              callback("Please select a valid date range");
              return;
            }
            callback();
          },
          validateTicketCount(rule, value, callback) {
            if (!this.newSubscription.isUnlimited) {
              if(!this.newSubscription.ticket_count) {
                callback("Please specify a ticket count");
                return;
              }
            }
            callback();
          },
          datePicked() {
            this.dateWindow = null;
            console.log("date window", this.dateWindow);
          }
        },
    };
</script>

<style lang="scss" scoped>
  .header {
    display: flex;
    align-items: center;
    margin: 10px 0;
    justify-content: space-between;
    h3 {
      margin: 0;
    }
  }
  .ticket-info {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
  }

  .subscription-box {
    margin-top: 20px;
    .header {
      display: flex;
      justify-content: space-between;
    }

    .actions {
      margin: 20px 0;
      display: flex;
      > * {
        flex: 1;
      }
    }

    /deep/ {
      .el-form-item {
        label {
          width: auto;
          text-align: left;
          float: none;
        }
        .el-input__inner {
          width: 100%;
        }
        .el-input-number {
          margin-right: 10px;
          width: 150px;
          .el-input__inner {
            width: 150px;
          }
        }
        .el-switch__label:not(.is-active) {
          color: #c0c0c0;
        }
      }
      .subscription-details {
        .el-form-item__content {
          font-size: 16px;
        }
      }
    }

    &.display {
      .el-form-item {
        margin-bottom: 0;
      }
    }

    &.no-sub {
      text-align: center;
    }
  }
</style>
<style lang="scss">
.el-date-table td.disabled div {
  background-color: #f0f0f0;
}
</style>