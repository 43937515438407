var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("h3", { staticClass: "org-header" }, [
            _vm._v(_vm._s(_vm.org.name)),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.isNewSubscription },
              on: {
                click: function ($event) {
                  return _vm.$emit("add")
                },
              },
            },
            [_vm._v("Add subscription")]
          ),
        ],
        1
      ),
      _vm.org.waiting_count > 0
        ? _c(
            "el-tag",
            { staticClass: "ticket-info", attrs: { type: "danger" } },
            [
              _vm._v(
                " " + _vm._s(_vm.org.waiting_count) + " waiting for tickets "
              ),
            ]
          )
        : _vm._e(),
      _vm.isNewSubscription
        ? _c(
            "el-card",
            { staticClass: "subscription-box" },
            [
              _c(
                "div",
                {
                  staticClass: "header",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("Add subscription"),
                  ]),
                ]
              ),
              _c(
                "el-form",
                {
                  ref: "addSubscription",
                  staticClass: "subscription-details",
                  attrs: { model: _vm.newSubscription, rules: _vm.rules },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Subscription period" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "subscription-date-range",
                        attrs: {
                          type: "daterange",
                          "start-placeholder": "Start date",
                          "end-placeholder": "End date",
                          format: "MMM d, yyyy",
                          "picker-options": _vm.pickerOptions,
                        },
                        on: { change: _vm.datePicked, blur: _vm.datePicked },
                        model: {
                          value: _vm.newSubscriptionPeriod,
                          callback: function ($$v) {
                            _vm.newSubscriptionPeriod = $$v
                          },
                          expression: "newSubscriptionPeriod",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Price", prop: "price" } },
                    [
                      _c(
                        "money",
                        _vm._b(
                          {
                            staticClass: "el-input__inner money",
                            model: {
                              value: _vm.newSubscription.price,
                              callback: function ($$v) {
                                _vm.$set(_vm.newSubscription, "price", $$v)
                              },
                              expression: "newSubscription.price",
                            },
                          },
                          "money",
                          _vm.money,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Ticket duration",
                        prop: "ticket_duration",
                      },
                    },
                    [
                      _c("el-input-number", {
                        model: {
                          value: _vm.newSubscription.ticket_duration,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.newSubscription,
                              "ticket_duration",
                              $$v
                            )
                          },
                          expression: "newSubscription.ticket_duration",
                        },
                      }),
                      _vm._v(" months "),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Tickets", prop: "ticket_count" } },
                    [
                      _c(
                        "div",
                        [
                          _c("el-input-number", {
                            attrs: {
                              disabled: _vm.newSubscription.isUnlimited,
                            },
                            model: {
                              value: _vm.newSubscription.ticket_count,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newSubscription,
                                  "ticket_count",
                                  $$v
                                )
                              },
                              expression: "newSubscription.ticket_count",
                            },
                          }),
                          _c("el-switch", {
                            attrs: { "active-text": "unlimited" },
                            model: {
                              value: _vm.newSubscription.isUnlimited,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.newSubscription,
                                  "isUnlimited",
                                  $$v
                                )
                              },
                              expression: "newSubscription.isUnlimited",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("el-divider"),
                  _c(
                    "div",
                    { staticClass: "actions" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.saveSubscription },
                        },
                        [_vm._v("Save")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "actions" },
                    [
                      _c("el-button", { on: { click: _vm.cancelAdd } }, [
                        _vm._v("Cancel"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.selectedSubscription
        ? _c(
            "el-card",
            { staticClass: "subscription-box display" },
            [
              _c(
                "div",
                {
                  staticClass: "header",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("Selected subscription"),
                  ]),
                  _vm.selectedSubscription.is_active
                    ? _c("span", { staticClass: "active" }, [
                        _c("i", { staticClass: "el-icon-date" }),
                        _vm._v(" Active"),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "el-form",
                { staticClass: "subscription-details" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Subscription period", prop: "period" } },
                    [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm._f("dateformat")(
                              _vm.selectedSubscription.start_date
                            )
                          ) +
                            " - " +
                            _vm._s(
                              _vm._f("dateformat")(
                                _vm.selectedSubscription.end_date
                              )
                            )
                        ),
                      ]),
                    ]
                  ),
                  _c("el-form-item", { attrs: { label: "Price" } }, [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(_vm.selectedSubscription.price)
                        )
                      ),
                    ]),
                  ]),
                  _c("el-form-item", { attrs: { label: "Ticket duration" } }, [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.selectedSubscription.ticket_duration) +
                          " months"
                      ),
                    ]),
                  ]),
                  _vm.selectedSubscription.deleted_at == null
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "Tickets" } },
                        [
                          _vm.selectedSubscription.ticket_count > 0
                            ? _c(
                                "el-tag",
                                {
                                  staticClass: "ticket-info",
                                  attrs: {
                                    type: _vm.subscriptionHealth(
                                      _vm.selectedSubscription
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.selectedSubscription.tickets_claimed
                                      ) +
                                      " / " +
                                      _vm._s(
                                        _vm.selectedSubscription.ticket_count
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _c("el-tag", { staticClass: "ticket-info" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selectedSubscription.tickets_claimed
                                    ) +
                                    " / Unlimited "
                                ),
                              ]),
                        ],
                        1
                      )
                    : _c(
                        "el-form-item",
                        { attrs: { label: "Tickets" } },
                        [
                          _vm.selectedSubscription.ticket_count > 0
                            ? _c(
                                "el-tag",
                                {
                                  staticClass: "ticket-info",
                                  attrs: {
                                    type: _vm.subscriptionHealth(
                                      _vm.selectedSubscription
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.selectedSubscription
                                          .all_tickets_claimed
                                      ) +
                                      " / " +
                                      _vm._s(
                                        _vm.selectedSubscription.ticket_count
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _c("el-tag", { staticClass: "ticket-info" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selectedSubscription
                                        .all_tickets_claimed
                                    ) +
                                    " / Unlimited "
                                ),
                              ]),
                        ],
                        1
                      ),
                  _vm.selectedSubscription.deleted_at == null
                    ? [
                        !_vm.isPastSubscription &&
                        _vm.selectedSubscription.ticket_count > 0
                          ? _c(
                              "div",
                              { staticClass: "actions" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", plain: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("tickets")
                                      },
                                    },
                                  },
                                  [_vm._v("Add tickets")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", plain: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$emit("unlimited")
                                      },
                                    },
                                  },
                                  [_vm._v("Convert to unlimited")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("el-divider"),
                        _c(
                          "div",
                          { staticClass: "actions" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit(
                                      "delete",
                                      _vm.selectedSubscription
                                    )
                                  },
                                },
                              },
                              [
                                _vm.selectedSubscription.tickets_claimed > 0
                                  ? _c("span", [
                                      _vm._v(" Terminate subscription "),
                                    ])
                                  : _c("span", [
                                      _vm._v(" Delete subscription "),
                                    ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : _c(
                        "div",
                        [
                          _c("el-divider"),
                          _c(
                            "el-tag",
                            {
                              staticClass: "ticket-info",
                              attrs: { type: "info" },
                            },
                            [_vm._v("TERMINATED")]
                          ),
                        ],
                        1
                      ),
                ],
                2
              ),
            ],
            1
          )
        : _c("el-card", { staticClass: "subscription-box display no-sub" }, [
            _c("p", [_vm._v("No subscription selected.")]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }